.sales-page-main {
    .filter-btns {
        font-size: 13px;
        padding: 5px 10px 0px 10px;
        font-weight: 400 !important;
        width: initial;
        min-width: 100px;
        height: 35px;
        text-shadow: none;

        &:hover {
            color: #ffffff;
            background-color: #798892;
            border: 1px solid #798892;
            box-shadow: none;
            outline: none;
        }

        &:focus {
            color: #ffffff;
            background-color: #798892;
            border: 1px solid #798892;
        }
    }


    .fltr-hpr {
        padding: 6.4px 25px !important;
        height: 40px !important;
        max-width: 100% !important;
        min-width: 50% !important;
        text-shadow: none;

        &:hover {
            color: #ffffff;
            background-color: #000;
            border: 1px solid #000;
            box-shadow: none;
            outline: none;
        }

        &:focus {
            color: #ffffff;
            background-color: #010b40;
            border: 1px solid #010b40;
        }
    }
    .fltr-hpr {
    &.m-width-100 {
        min-width: 100px!important;
    }
    }
}

.aff-payment {
    .transactions-box {
        border: 1px solid #d6dee5;
        box-shadow: 0 0 2px 1px #e4e7ed;
        background: #fff;

        .table_heading {
            background: #010b40;
            color: #fff;
        }

        thead {
            tr {
                th {
                    text-transform: none;
                    font-family: Nunito Sans, sans-serif;
                    font-weight: 700;
                }

            }
        }

        tbody {
            tr {
                td {
                    padding: 0.5rem 0.75rem;
                    font-size: 13px;

                    &.sales-event-link {
                        a {
                            color: #010b40;

                            &:hover {
                                color: #134a7c;
                            }
                        }
                    }

                    .order-number-btn {
                        cursor: pointer;
                    }
                }

                &:hover {
                    background-color: #f6f6f7;
                }
            }
        }
    }

}


.custom-paginate-new {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.custom-paginate-new>.active>a {
    background-color: #010b40;
    border-color: #010b40;
    color: #fff;
}

.custom-paginate-new>li>a {
    border: 1px solid #010b40;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.custom-paginate-new>.active>a,
.custom-paginate-new>.active>a:focus,
.custom-paginate-new>.active>a:hover,
.custom-paginate-new>.active>span,
.custom-paginate-new>.active>span:focus,
.custom-paginate-new>.active>span:hover {
    background-color: #fff !important;
    border: 1px solid #010b40 !important;
    outline: none;
    color: #010b40 !important;
}

.custom-paginate-new>li:first-child>a,
.custom-paginate-new>li:first-child>span,
.custom-paginate-new>li:last-child>a,
.custom-paginate-new>li:last-child>span {
    border-radius: unset;
}

.custom-paginate-new .custom-paginate-link,
.custom-paginate-new .custom-paginate-next,
.custom-paginate-new .custom-paginate-prev {
    background-color: #010b40;
    color: #fff;
}

.custom-paginate-new .custom-paginate-link {
    min-width: 40px !important;
    height: 40px;
    line-height: 2 !important;
}

.custom-paginate-new .page-item.disabled .page-link {
    border: 1px solid #d6dee5;
    color: #ccc;

}

.custom-paginate-new .custom-paginate-next,
.custom-paginate-new .custom-paginate-prev {
    min-width: 40px !important;
    height: 40px;
    line-height: 2 !important;
}

.custom-paginate-new .custom-paginate-prev:before {
    content: "\f053";
    font-family: FontAwesome;
}

.custom-paginate-new .custom-paginate-next:before {
    content: "\f054";
    font-family: FontAwesome;
}



.sales-summery {
    // margin-top: -56px;
    margin-top: 0;
}

.summarry-box {
    border: 1px solid #d6dee5;
    background: #fff;
    padding: 10px;
    font-family: Nunito Sans, sans-serif;
    font-weight: 700;
}

.summarry-box h5 {
    border-bottom: 1px solid #d6dee5;
    padding-bottom: 10px;
}

.fulfil-status {
    // background-color: #ffc107;
    // background-color:#ffea8a;
    color: #000000;
    border-radius: 100px;
    display: inline-block;
    padding: 2px 10px;
    font-size: 12px;
}

.unfulfilled {
    background-color: #ffea8a;
    color: #000000;
}

.fulfilled {
    background-color: #28a745;
    color: #ffffff;
}

.partially-fulfilled {
    background-color: #ffd79d;
    color: #000000;
}

.scroll-area {
    overflow: hidden;
    max-height: 500px;
    padding-right: 10px;
}

.order-detail-title-area {
    padding: 4px 0 0 45px;
    position: relative;
    margin-bottom: 20px;

    .back-btn {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
    }

    .order-date {
        font-size: 13px;
    }

    .title-info-main {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        padding-bottom: 5px;

        .order-number {
            font-family: Nunito Sans, sans-serif;
            font-weight: 700;
            font-size: 17px;
        }

        .fulfil-status {
            // background-color: #ffc107;
            // background-color:#ffea8a;
            color: #000000;
            border-radius: 100px;
            display: inline-block;
            padding: 2px 10px;
            font-size: 12px;
        }

        .unfulfilled {
            background-color: #ffea8a;
            color: #000000;
        }

        .fulfilled {
            background-color: #28a745;
            color: #ffffff;
        }

        .partially-fulfilled {
            background-color: #ffd79d;
            color: #000000;
        }

        .paid {
            background-color: #e4e5e7;
            color: #000000;
        }

        .unpaid {
            background-color: #ffd79d;
            color: #000000;
        }
    }
}

.order-detail-box {
    background-color: white;
    border-radius: 5px;
    // box-shadow: 0 0 2px rgb(0 0 0 / 26%);
    box-shadow: 0 0 0.2125rem #1718180d, 0 0.0625rem 0.125rem #00000026;
    border: 1px solid #e1e3e5;
    padding: 20px 25px;
    margin-bottom: 15px;
}

.status-heading {
    // position: relative;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    font-family: Nunito Sans, sans-serif !important;

    .txt {
        line-height: inherit;

    }
}

.order-details-listing-main {
    position: relative;
    padding:20px 0;
    border-bottom: 1px solid #e1e3e5;
    

    .left-content {
        position: relative;
        padding-left: 70px;

        .order-pic-wrapper {
            position: absolute;
            left: 15px;
            top: 0px;

            .order-pic-main {
                width: 38px;
                height: 38px;
                border-radius: 5px;
                position: relative;
                border: 1px solid #e1e1e1;

                .order-count {
                    width: 25px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    border-radius: 100%;
                    background-color: #e4e5e7;
                    color: black;
                    font-size: 12px;
                    position: absolute;
                    right: -12px;
                    top: -12px;
                    z-index: 99;
                }

                .order-pic {
                    width: 100%;
                    overflow: hidden;

                    img {
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        max-height: 100%;
                        max-width: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                }
            }
        }

        .order-name {
            a {
                color: #2c6ecb;
                text-decoration: underline;
                font-size: 14px;
                &:hover {
                    color: #1f5199;
                    text-decoration: none;

                }
            }
        }
        .order-style {
            font-size: 14px;
        }
        .order-sku {
            font-size: 14px;
        }
        .order-discount {
            font-size: 14px;
        }

    }
    .order-price-status {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        .cut-price {
            color: #2c6ecb;
        }
    }


}
.order-details-listing-main:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;

}
.customer-box {
    padding: 20px 0px;
    .status-heading {
        padding: 0 20px 0 20px;
        margin-bottom: 0;
    }
    .customer-info {
        padding: 15px 20px;
        border-bottom: 1px solid #e1e3e5;
        .customer-hd {
            font-size:13px ;
            font-weight: 700;
            text-transform: uppercase;
            font-family: Nunito Sans, sans-serif !important;
            padding-bottom: 5px;
        }
        .customer-name {
            font-size: 14px;
        }
        .number-of-orders {
            font-size: 14px;
        }
        .customer-link {
            a {
                color: #2c6ecb;
                text-decoration: none;
                font-size: 14px;
                &:hover {
                    color: #1f5199;
                    text-decoration: none;

                }
            }
        }
        &.last-box {
            border-bottom: 0px;
            padding-bottom: 0px;
        }
    }
}

.paid-rows {
    font-size: 14px;
}

.paid-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e3e5;
}
.paid-mid-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    padding: 3px 0;
    .multi-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
        .left-hd {
            min-width: 120px;
        }

    }
    .fw-bold {
        font-weight: 700;
        font-family: Nunito Sans, sans-serif !important;
    }
}
.paid-bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    padding-top: 15px;
    margin-top: 10px;
    border-top: 1px solid #e1e3e5;
}

@media only screen and (max-width: 1200px) {
    .sales-summery {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 991px) {
    .sales-page-main {
        .fltr-hpr {
            min-width: 120px !important;
            max-width: 120px !important;
        }
    }
}