@import "../../css/app.scss";

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 21px;
    margin: 0 $spacer;
  }
}