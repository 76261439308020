/* play button effect */
@mixin rings($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65, 0, .34, 1);
    z-index: -1;
}

.request-loader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.35);

    &::after {
        @include rings(3s, 0s);
    }

    &::before {
        @include rings(3s, 0.5s);
    }
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale3d(0.9, 0.9, 1);
    }

    to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
    }
}

.video-modal {
    &.video-modal {
        .modal-content {
            .modal-header {
                .close {
                    right: -45px;
                    top: -16px;
                    font-size: 3rem;
                    color: #fff;
                    text-shadow: 0 1px 0 #000;

                    &:focus {
                        outline: none;
                        border: 0px;
                    }
                }
            }
        }
    }
}

.get-started {
    padding-top: 60px;
    background-color: #ffffff;
}

.who-we-are-main {
    width: 100%;
    padding: 180px 0;
    margin: 0px 0 60px 0;
    // background-image: url(../images/video-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .who-we-are-content {
        text-align: center;
        width: 100%;

        h2 {
            color: #ffffff;
            font-weight: 800 !important;
            font-size: 30px;
            text-align: center;
            text-transform: uppercase;
        }

        .play-button {
            margin: 70px auto 0px auto;
            position: relative;
            z-index: 100;
            display: flex;
            justify-content: center;

            a {
                background-color: #ffffff;
                position: relative;
                width: 60px;
                height: 60px;
                display: table;
                margin: auto;
                border-radius: 100px;

                i {
                    color: #000;
                    font-size: 20px;
                    position: relative;
                    top: 21px;
                    left: 3px;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    i {
                        color: #434e87;
                    }
                }
            }
        }

    }
}

.brand-sec-banner {
    &.about-sec-banner {
        background-color: #ffffff;
        // border-bottom: solid 1px #dce4ec;

        .brn-bnr-box-left {
            .bnr-content {
                h1 {
                    color: #000000;
                }

                p {
                    color: #000000;
                }

                a {
                    background-color: #010b40;
                    color: #ffffff;


                    &:hover {
                        background-color: #000;
                        color: #FFF;
                    }
                }
            }
        }

        .brn-bnr-box-right {
            .about-img {
                // width: initial;
                // height: inherit;
                // object-position: initial;
                // object-fit: initial;
                // display: table;
                // margin: auto;
            }
        }
    }
}


.bioshop-sec-main {
    &.get-paid {
        background-color: white;
        // padding: 60px 0;

        .bioshop-container {
            gap: 25px;

            .bioshop-box {
                &.flex-end {
                    justify-content: flex-end;
                }

                .bioshop-box-inner {
                    .bioshop-link {
                        a {
                            background-color: #010b40;
                            color: #ffffff;

                            &:hover {
                                background-color: #000;
                                color: #FFF;
                            }

                        }
                    }
                }
            }

        }

        &.space {
            padding: 100px 0;
        }
    }
}


.meet-out-team {
    background-color: #ffffff;

    .team-wrapper {
        padding: 60px 0;
        width: 100%;

        .team-header {
            display: flex;
            max-width: 1140px;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin: auto;

            h2 {
                color: #252525;
                font-weight: 800 !important;
                font-size: 30px;
                text-align: center;
            }

            p {
                font-size: 16px;
            }
        }

        .team-boxes-main {
            // width: 1140px;
            margin: 0 auto;
            margin-top: 40px;
            display: flex;
            justify-content: space-between;

            .team-box {
                flex: 0 0 28%;
                max-width: 28%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .team-icon {
                    width: 150px;
                    height: 150px;
                    flex-basis: 150px;
                    position: relative;
                    overflow: hidden;
                    transition: box-shadow 0.25s;
                    padding: 0px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    border: 3px solid #E5E0FA;
                    border-radius: 100px;
                    transition-duration: .3s;
                    transition-property: transform;
                    transition-timing-function: ease-out;

                    &:hover {
                        transform: translateY(-8px);
                    }

                    img {
                        border-radius: 100px;
                    }
                }

                .team-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h2 {
                        color: #000;
                        margin-top: 0.5em;
                        font-size: 24px;
                        font-weight: 700 !important;
                        margin-bottom: 5px
                    }

                    p {
                        color: #000;
                        text-align: center;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.about_sec_main {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;

    .about_sec_content {
        width: 100%;

        .about_sec_left {}

        .about_sec_right {
            // padding-left:60px ;
            max-width: 550px;

            .about-img {
                max-width: 100%;
                height: auto;
            }

            h2 {
                color: #252525;
                font-weight: 800 !important;
                font-size: 30px;
                margin-bottom: 20px;
            }

            h3 {
                color: #252525;
                font-weight: 800 !important;
                font-size: 18px;
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

.bioshop-sec-main {
    &.about-full-sec {
        background-color: #2D266C;

        .bioshop-container {
            .bioshop-box {
                img {
                    // width: initial;
                }

                .bioshop-box-inner {
                    h2 {
                        color: #ffffff;
                    }

                    p {
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1270px) {

    .who-we-are-main {
        padding: 100px 0;
    }

}

@media only screen and (max-width: 990px) {

    .who-we-are-main {
        padding: 60px 0;
        margin: 0px 0 40px 0;
    }


    .meet-out-team {
        .team-wrapper {
            padding: 40px 0;
        }
    }

    .about_sec_main {
        margin-top: 40px;
        margin-bottom: 40px;

        .about_sec_content {
            .about_sec_left {
                margin-bottom: 20px;
            }

            .about_sec_right {
                // padding-left:60px ;
                max-width: initial;

                .about-img {
                    margin-bottom: 20px;
                }

                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                }

                h3 {
                    color: #252525;
                    font-weight: 800 !important;
                    font-size: 18px;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .who-we-are-main+.get-started {
        padding-top: 30px;
    }

    .who-we-are-main {
        padding: 40px 0;
        margin: 0px 0 25px 0;

        .who-we-are-content {
            h2 {
                font-size: 1.5rem;
            }

            .play-button {
                margin: 30px auto 0px auto;
            }
        }
    }

    .meet-out-team {
        .team-wrapper {
            padding: 20px 0;

            .team-header {
                h2 {
                    font-size: 20px;
                }

                p {
                    font-size: 14px;
                }
            }

            .team-boxes-main {
                margin-top: 20px;
                display: block;

                .team-box {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 25px;

                    .team-icon {
                        width: 100px;
                        height: 100px;
                        flex-basis: 100px;
                        transition: box-shadow 0.25s;
                        padding: 4px;
                    }

                    .team-content {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        h2 {
                            color: #000;
                            margin-top: 0.5em;
                            font-size: 18px;
                            font-weight: 700 !important;
                            margin-bottom: 5px
                        }

                        p {
                            color: #000;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 576px) {
    .video-modal {
        &.video-modal {
            .modal-content {
                .modal-header {
                    .close {
                        right: 0px;
                        top: -50px;
                    }
                }
            }
        }
    }

}