.cat-box {
  text-align: center;
  margin-top: 20px;
  margin-top: 10px;
  //   padding-right: 20px !important;
  //   padding-left: 20px !important;
}
.cat-box .cat-image {
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid;
  width: 100%;
  height: 55px;
}
.cat-lable {
  font-weight: 700;
  font-family: 'SegoeUIBold';
  font-size: 0.8rem;
  padding-top: 5px;
  padding-bottom: 15px;
}
.cat-box .action {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 29%;
  // top: 35%;
  left: 48%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  display: inline-flex;
}
.cat-box:hover .cat-image {
  opacity: 0.3;
}
.cat-box .edit-icon {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.cat-box .edit-icon span {
  margin-left: 5px;
}
.cat-box:hover .action {
  opacity: 1;
}
.profile-icon {
  object-fit: cover;
}
.edit-cat-icon {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: 10px 0;
  border:1px solid #c1c1c1;
}
