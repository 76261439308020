.stream-box-main {
    width: 100%;
    // display: flex;
    // flex-direction: column;
    background-color: #ffffff;
    padding: 25px 40px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);

  }
  .stream-box-main .ant-slider-handle {
    border: solid 2px #010b40;
  }
  .stream-box-main .ant-slider-handle:focus {
    border-color: #010b40;
  }
  .stream-box-main .ant-slider-track,
  .stream-box-main .ant-slider .ant-slider-track
  {
    background-color: #010b40;
  }
  .loadingstream {
    height: 427px;
  }