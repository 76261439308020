.social_box_main {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  position: relative;
}

.conn-count {
  font-size: 1rem;
}
.connection-status-badge-red {
  position: absolute;
  right: 10px;
  background: red;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 9pt;
  font-weight: 600;
  font-family: "SegoeUIBold";
}
.connection-status-topbar-badge-green {
  position: absolute;
  right: 280px;
  background: green;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 9pt;
  font-weight: 600;
  font-family: "SegoeUIBold";
}

.connection-status-badge-green {
  position: absolute;
  right: 10px;
  background: green;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 9pt;
  font-weight: 600;
  font-family: "SegoeUIBold";
}