.separator-line {
  border-top: 1px solid #000;
  border-color: #ccc;
  // margin-top: 0px;
}
.profile_box_main.col-md-4,
.profile_box_main.col-md-8 {
  padding-left: 15px;
  padding-right: 15px;
}
.brand_container_main .row .profile_box_main .dash_block_profile {
  min-height: 300px;
}
.brand_container_main.container {
  padding: 0;
  margin: 0;
}
.profile_container_main.container {
  padding-left: 0;
  padding-right: 0;
}
.profile_box_main.col-md-6 {
  padding-right: 15px;
  padding-left: 15px;
}
.dash_block_profile {
  margin-bottom: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 1px #e4e7ed;
  min-height: 493px;
}
.profile_password.profile_box_main .dash_block_profile {
  min-height: 100px;
}
.dash_content_profile {
  transition: opacity 0.2s ease-out;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  overflow-x: visible;
}
.dash_content_profile hr {
  border-color: #dce4ec;
}
.dash_content_profile h5 {
  border-bottom: 1px solid #dce4ec;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.hashtag-box .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.hashtag-box .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.hashtag-box .form-control:focus {
  border-color: #dce4ec;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #c1ccd3;
  border-radius: 0.3rem;
}
