.any-image-box-iner {
&.posting-image {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    background-color: #010b40;
    img {
        max-width: 100%;
        max-height: 100%;
        width: initial!important;
        height: initial!important;
    }
}
} 

.post-modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #010b40;
    border: 1px solid #c1c1c1;
    height: 250px;
    .post-image-left {
        max-width: 100%;
        max-height: 100%;
    }
}
 .publish-post-detail {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #ede9e9;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
   .publish-post-detail:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  .top-space {
    margin-top: -60px;
  }
  .publish-post-detail .count {
    font-size: 13px;
    margin-bottom: 3px;
  }
  .publish-post-detail .count-title {
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 3px;
  }
   .publish-post-detail:last-child .count{
    margin-bottom: 0;
  }
   .publish-post-detail:last-child .count-title{
    margin-bottom: 0;
  }
  .publish-post-detail:last-child {
    border-bottom: none;
    margin-bottom: 0;
}


.preview-box-content {
  background: #f0f3fd;
  border: dashed 1px #9ea4b8;
  padding: 10px !important;
  width: 90%;
  display: flex;
  border-radius: 5px;
  word-break: break-all;
}
.pre-img-upload {
  width: 66px;
  height: 66px;
  position: relative;
}
.pre-img-upload img {
  width: 66px;
  height: 66px;
  box-shadow: 0 0 0 3px rgb(126 125 125 / 40%);
  border-radius: 50%;
}
.pre-content-upload {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 20px;
}
.pro-brar-ift span.glyphicon {
  top: -3px;
  cursor: pointer;
}
.glyphicon-remove:before {
  content: "\E014";
}