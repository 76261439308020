
.affiliate_p_col,
.affiliate_in_col {
  flex: 0 0 100%;
  max-width: 100%;
}
.tab-content.affiliate_tab_ift {
  position: static;
}
.affiliate_tab_ift .tab-pane .aff-container-fulid {
  padding-left: 0;
  padding-right: 0;
}
.affiliate-page .af-rm-mn {
  margin-left: 0;
  margin-right: 0;
}
.create-affiliate .app_main_cont_ift .right-bar {
  padding-top: 20px;
  padding-bottom: 20px;
}


.bioshop-area {
  // max-height: 712px;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;

  // grid-row-gap: 32px;
  // grid-column-gap: 24px;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  display: grid!important;
  grid-template-columns: repeat(4,minmax(0,1fr));

}
.bioshop-area::-webkit-scrollbar {
  width: 10px;
}

.bioshop-area::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.bioshop-area::-webkit-scrollbar-thumb,
.bioshop-area::-webkit-scrollbar-thumb:hover {
  background: #010b40 !important;
  border-radius: 10px;
}

.affiliate-model.image-edit-box {
  border: none;
  padding: 0;
  box-shadow: none;
}
.affiliate-page .image-post-box-aff {
  position: relative;
  // max-width: 33.333%;
  // flex: 0 0 33.333%;
  cursor: pointer;
}


.affiliate-page .image-post-box-aff:after {
  content: "";
  padding-top: 100%;
  display: block;
}
.affiliate-page .image-post-box-aff .image-post-box-aff-inr {
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border: 1px solid;
  border-color: #f6f6f6 #f3f3f3 #eee;
}
.affiliate-page
  .image-post-box-aff
  .image-post-box-aff-inr
  .image-post-box-aff-inr-inr {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  margin: 0;
  display: block;
  border-radius: 10px;
}
.right-bar-affiliate {
  flex: 0 0 70% !important;
  max-width: 70% !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.image-post-box-aff-inr-inr .post-image {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 0px !important;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 0;
  object-position: 50% 0;
}
.create_campaign_heading {
  display: flex;
  max-width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.camp-row.row {
  flex-direction: column;
}


.affiliate-page.bio-main .image-post-box-aff {
  max-width: 33.333%;
flex: 0 0 33.333%;
}

.affiliate-page.bio-main
  .image-post-box-aff
  .image-post-box-aff-inr
  .image-post-box-aff-inr-inr {
    
  border-radius: 0px;
  }
  .media-post-new {
  }
  .media-post-new .btn-play {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 999;
    color: #fff;
    width: 22px;
    height: 22px;
    text-align: center;
    background-color: #222;
    border-radius: 6px;
  }
  .media-post-new .btn-play .fa {
    font-size: 10px;
    position: relative;
    top: -1px;
  }
  .video-inner-media {
    max-height: 400px;
    object-fit: cover;
  }
  .bio-copy-link .item-a {
    height: auto !important;
  }

.analytic-box.campaign-box .analytic-caption {
  padding-left: 20px;
}



/* .campaign-box .camp-row .any-post-img-col .any-post-image:after{
  padding-top:60%;
} */
.campaign-box .camp-row .any-post-img-col .any-post-image .any-image-box {
  border: none;
}
.campaign-box
  .camp-row
  .any-post-img-col
  .any-post-image
  .any-image-box-iner
  img {
  object-position: center top;
  border-radius: 0.3rem !important;
  border: none;
  padding: 0;
}
.analytic-box.campaign-box .count-box:last-child {
  margin-bottom: 0;
  border: none;
}
.campaign-header .campaign-icon {
  width: 55px;
  height: 55px;
}
.campaign-header.col-12 {
  display: flex;
  justify-content: space-between;
}
.campaign-header h6 {
  font-family: "SegoeUIBold" !important;
  margin-bottom: 1rem;
}
.analytics-page.affiliate-page .tab-content > .tab-pane.tab-create-campaign {
  padding: 0;
  width: 100%;
  height: -moz-calc(100% - 57px);
  height: -webkit-calc(100% - 57px);
  height: -o-calc(100% - 57px);
  height: calc(100% - 57px);
  position: absolute;
  left: 0;
}
.aff-active-circle {
  font-family: "SegoeUIBold";
}
.threedots:after {
  content: "\2807";
  font-size: 30px;
  color: black;
}
.dropdown a {
  color: rgba(0, 0, 0, 0.85);
}
.dropdown .fa-2x {
  font-size: 1.5rem;
}
.dropdown .fa-2x {
  font-size: 1.45rem;
}
.cmp-h-right.col-md-6 {
  display: flex;
  justify-content: right;
  padding: 0;
}
.camp-type-ift.col-md-12 {
  align-items: center;
}
.camp-type-ift .n-camp-type {
  font-family: "SegoeUIBold";
}
.col1 {
  margin-right: 30px;
}
.col1:last-child {
  margin-right: 0;
}
.col1 label {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col1 label span.imp-click {
  width: 55px;
  height: 55px;
  background: #495057;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.imgbgchk:checked + label > span.imp-click {
  background: #010b40;
}
.imgbgchk:checked + label > span.imp-name {
  color: #010b40;
}
.demographic-section .country-select .row .col-md-2 .form-control {
  min-height: 38px;
}
.add-del-btns span {
  display: flex;
  width: 20px;
  height: 38px;
  /* background: #495057;
  border:solid 1px #495057;
  border-radius: 4px; */
  justify-content: center;
  align-items: center;
  color: #000;
}
.add-del-btns span .glyphicon {
  top: 0;
}
.country-select .c-con-select {
  align-items: flex-end;
}
.add-del-btns button strong {
  display: none;
}
.swal2-title {
  font-size: 1.1rem !important;
}
/* .aff-img-edit-link.image-edit-links {
  width: 100%;
  max-width: 100% !important;
  flex: auto !important;
} */
.no-data-found-mobile {
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
  font-family: "SegoeUIBold";
}

.image-post-box-aff-inr-inr video {
  max-width: 100%;
  height: auto;
}

.scroll-box .image-post-box-aff {
  cursor: move;
}
.scroll-box .post-image {
  width: 100%;
  height: 100%;
  object-position: 50% 0;
  object-fit: cover;
}

// /**********bioshop post*************/
.bioshop_container_main {
    // max-width: 680px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .bioshop-title .col-sm-6 {
    padding: 0;
}
.bioshop-title .page-title {
  // margin: 0 0 calc(1rem + 6px);
  margin: 0;
}
  .bioshop-body {
    position: relative;
  }
  .bioshop-area .post-image {
    cursor: move;
  }
  .bioshopaction {
    display: flex;
    // justify-content: flex-end;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .bioshoplimit {
    min-width: 40%;
    margin-right: 10px;
  }
  .bioshop-area .image-post-box-aff {
    height: fit-content;
  }



  .main-carousel .circles {
    border-radius: 5% !important;
    // border: 1px solid #ccc;
    // padding: 2px;
    width: 150px;
    height: 65px;
    box-shadow: 0 4px 8px #cccccc;
  }
  .create-affiliate .main-carousel .circles {
    border-radius: 50% !important;
    border: 1px solid #ccc;
    padding: 2px;
    width: 65px;
    height: 65px;
    box-shadow: none;
  }
  .main-carousel span {
    display: block;
    padding-top: 0.3rem;
    font-weight: 800;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.7px;
  }
  .main-carousel .carousel-items {
    text-align: center;
  }
  .react-multiple-carousel__arrow {
    min-height: 100% !important;
    min-width: auto !important;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(0% + -6px) !important;
    background: none !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(0% + -6px) !important;
    background: none !important;
  }
  .react-multiple-carousel__arrow::before {
    color: #ccc !important;
  }

  .main-carousel .carousel-items a,
  .main-carousel .carousel-items button {
    text-transform: capitalize;
    font-weight: 600;
    color: #000;
  }
  .main-carousel .carousel-items a:hover,
  .main-carousel .carousel-items button:hover {
    color: #222;
    text-decoration: none;
  }
  .main-carousel .carousel-items a:focus,
  .main-carousel .carousel-items button:focus {
    color: #222;
    text-decoration: none;
  }
  .main-carousel .react-multi-carousel-track {
    padding: auto;
  }

  .dashboard-content-container .dashboard-content-full .dashboard-inner-content.store-style {
    padding: 0rem;
}


.image-edit-box {
  background: #fff;
  padding: 30px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 1px #e4e7ed;
  position: relative;
}
span.fa.fa-times.ift-cancel {
  position: absolute;
  font-size: 20px;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.edit_button_main {
  display: flex;
  width: 100%;
}
.image-box {
  flex: 0 0 26%;
  max-width: 26%;
  align-items: center;
  justify-content: center;
}



.linked_edit_box {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.linked_edit_box .p-5 {
  padding: 1rem !important;
}
.mobile-preview .custome_link {
  overflow: auto !important;
  width: 250px !important;
  height: 410px !important;
}
.mobile-preview .custome_link .row .col-12 {
  padding: 0;
}

  @media screen and (max-width: 768px) {
    .react-multi-carousel-item {
      text-align: center;
      word-break: break-word;
      /* margin: 0px 15px; */
    }
    .react-multi-carousel-list span {
      font-weight: 400;
      font-size: 0.8rem;
      text-transform: uppercase;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* width: 75px; */
      /* display: inline-block; */
    }
    .react-multi-carousel-list {
      overflow-x: scroll !important;
    }
    .react-multi-carousel-list::-webkit-scrollbar {
      display: none !important;
    }
    .react-multiple-carousel__arrow {
      display: none;
    }
  }
  @media (max-width: 500px) and (min-width: 280px) {
    img.circles {
      width: 56px;
      height: 56px;
    }
    ul.react-multi-carousel-track {
      transition: transform 10000s ease-in-out !important;
    }

    .react-multi-carousel-list span {
      // width: 75px;
    }
  }
  .btn-link {
    background: transparent;
    border: 0;
    outline: 0;
    color: #1a86d0;
    padding: 0;
}
.react-multiple-carousel__arrow--right {
  right: -6px!important;
  background: none!important;
}
.react-multiple-carousel__arrow:before {
  color: #010b40!important;
  font-size: 20px!important;
  font-weight: 700;
}
  @media (max-width: 712px) {
    .bioshop_container_main {
      margin: 0 15px;
      overflow: hidden;
    }
    .bioshop_container_main .page-title {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .bioshop_container_main .bioshop-title {
      margin: 0px;
    }
    .bioshopaction {
      margin-bottom: 10px;
      justify-content: space-between;
    }
    .bioshoplimit {
      min-width: 50%;
    }
  }
  
  /*************bioshop Links**********************/
  .bioshop-link {
    position: relative;
  }
  
  .biolink-area {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flow-root !important;
  }
  .link-items {
    background: #185887;
    border: 1px solid #185887;
    height: 50px;
    margin-bottom: 10px;
    list-style: none;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    font-size: 1rem;
  }
  @media (max-width: 712px) {
    .biolink-area {
      display: inline;
      margin: 0;
      max-height: 540px;
    }
    .bioshop-link .bioshopaction {
      justify-content: end;
    }
  }
  


  @media only screen and (max-width: 1300px) {
    .bioshop-area {
      // max-height: 712px;
      max-height: 650px;
      overflow-y: auto;
      overflow-x: hidden;
    
      // grid-row-gap: 32px;
      // grid-column-gap: 24px;
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      display: grid!important;
      grid-template-columns: repeat(3,minmax(0,1fr));
    
    }
  }


  @media only screen and (max-width: 767px) {

    .bioshop-area {
      grid-row-gap: 0px;
      grid-column-gap: 0px;
    
    }
  .affiliate-page
  .image-post-box-aff
  .image-post-box-aff-inr
  .image-post-box-aff-inr-inr {
  border-radius: 5px;
}


  }