.messaging-box-white {
  // min-height: 400px;
  position: relative;
  background-color: #ffffff;
  padding: 10px 10px 70px 15px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);

  .username-left {
    padding-bottom: 10px;
    font-size: 1.3rem;
    font-family: "Nunito Sans", sans-serif !important;
    font-weight: 600 !important;
  }

  .username-right {
    padding-bottom: 10px;
    font-size: 1.3rem;
    font-family: "Nunito Sans", sans-serif !important;
    font-weight: 600 !important;
    text-align: right;
  }

  .message-box-list {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0 5px 0 0;
    min-height: 350px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;

    .message-box {
      padding: 0 0 10px 0;
      display: flex;
      clear: both;

      .message-content {
        padding: 8px 10px;
        border-radius: 7px;
        background: #010b401a;
        color: #0e0e0e;
        max-width: 50%;
        min-width: 195px;

        .message-txt {
          padding: 0 0 12px 0;
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 600 !important;
          font-size: 14px;
          color: #0e0e0e;
        }

        .message-date {
          padding: 0 0 0 0;
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 600 !important;
          font-size: 12px;
          color: #939393;
        }
      }

      &.sent-box {
        .message-content {
          background: #f5f5f5;
          color: #0e0e0e;
        }
      }

      &.receive-box {
        justify-content: end;
      }
    }
  }

  .messaging-send-main {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    // background-color: lightgrey;
    // display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 10px 0 10px;

    .messaging-send-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input {
        font-family: "proxima-nova", "Source Sans Pro", sans-serif;
        float: left;
        border: none;
        padding: 12px 5px 12px 15px;
        color: #32465a;
        border: 1px solid #c1c1c1;
        border-radius: 5px 0 0 5px;

        &:focus {
          box-shadow: none;
        }
      }
      input::-webkit-input-placeholder {
        color: #75758b;
      }

      input:-moz-placeholder {
        color: #75758b;
      }

      input::-moz-placeholder {
        color: #75758b;
      }

      input:-ms-input-placeholder {
        color: #75758b;
      }

      button {
        float: right;
        border: none;
        width: 50px;
        height: 48px;
        padding: 12px 0;
        cursor: pointer;
        background: #010b40;
        color: #f5f5f5;
        border-radius: 0 5px 5px 0px;
        &:after {
          display: none;
        }
      }
    }
  }
}

.fb-right-content {
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  padding: 15px 30px;
}

.insights-box {
  .insights-box-grid {
    .insights-box-inner {
      position: relative;
      height: 200px;
      background-color: #dddddd;
      margin: 15px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .insight-img {
        max-width: 100%;
        max-height: 100%;
        // object-fit: cover;
        // width: 100%;
        // height: 100%;
      }
    }
  }
}
