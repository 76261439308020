.cta-fill-area {
  display: flex;
  align-self: center;
  justify-content: space-between;
  background-color: #134a7c;
  padding: 1rem 1.5rem;
  border-radius: 4px;

  .cta-para {
    color: white;
  }
}

.videoMirror {
  -moz-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.control-room-top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .control-top-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // align-self: center;
    gap: 15px;

    .ant-dropdown-button {
      .ant-dropdown-trigger {
        margin-left: 0px !important;
      }
    }
  }
}

.control-grid-views-left {
  width: 85%;
}

.control-grid-views-right {
  width: 15%;
  overflow-y: scroll;

}

.control-grid-views-right .grid-views {
  // height: 25%;
  width: 100%;
  overflow: hidden;
  border: 0;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid #ffffff;
  border-right: 6px solid #ffffff;
  color: #e5e5e5;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0;
}


.scrollbar-style-grid::-webkit-scrollbar {
  width: 5px;
}

.scrollbar-style-grid::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.scrollbar-style-grid::-webkit-scrollbar-thumb,
.scrollbar-style-grid::-webkit-scrollbar-thumb:hover {
  background: #010b40 !important;
  border-radius: 10px;
}

.control-grid-views-right .grid-views .grid-img {
  width: 100%;
}

.control-grid-views-right .grid-views:last-child {
  border-bottom: 0;
}

.control-boxes-main {
  display: flex;
  justify-content: space-between;
  align-self: center;
  gap: 25px;
  margin-bottom: 40px;

  .control-boxes-box {
    width: 70%;
    background-color: white;
    // padding: 25px 40px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);

    .live-stats-main {
      display: flex;
      justify-content: center;
      align-self: center;

      .live-stats-box {
        display: flex;
        align-self: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 20%;
        padding: 1rem 0.3rem;
        border-right: 1px solid #e9ecef;
        border-bottom: 1px solid #e9ecef;

        .stats-icon {
          margin-bottom: 0.25rem;

          svg {
            font-size: 13px;
          }

          &.tag {
            svg {
              font-size: 16px;
              color: #fe8300 !important;
            }
          }

          &.usd-txt {
            font-size: 13px;
            font-weight: 600;
            color: #54b2ae;
          }
        }

        .stats-value {
          font-size: 14px;
          font-weight: 600;
          color: #000000;
        }

        .stats-hd {
          font-size: 13px;
          font-weight: 400;
          color: grey;
        }

        &:last-child {
          border-right: 0px;
        }
      }
    }
  }

  .go-live-box {
    padding: 1rem 1rem;
    justify-content: flex-end;

    .default-btn {
      font-size: 13px;
      min-width: initial;
      height: 32px;
      padding: 5px 15px;

      .stream-icon {
        width: 18px;
        margin-right: 6px;
        position: relative;
        top: -1px;
      }

      .preview-icon+span {
        margin-left: 5px;
      }

      &.mute-btn {
        min-width: 100px;

        svg {
          position: relative;
          top: -1px;
        }

        span {
          margin-left: 5px;
        }
      }
    }

    .mirror-button-main {
      margin: 0 15px;

      .ant-form-item-label {
        label {
          margin-bottom: 0;
        }
      }

      .ant-form-item-control {
        min-width: 50px;
      }

      .ant-switch-checked {
        background: #010b40;
      }
    }
  }
}

.control-video-box-main {
  background-color: #000000;
  padding: 10px;
  min-height: 340px;
  display: flex;
  justify-content: center;
  position: relative;

  .settings-box-main {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;

    .settings-box {
      background: #efeff3;
      padding: 5px;
      border-radius: 5px;
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 15px;

      .setting-icon {
        color: grey;
      }
    }

    .settings-icon {
      cursor: pointer;
    }

    &:hover {
      .settings-box {
        background-color: white;

        .setting-icon {
          color: black;
        }
      }
    }
  }

  .box-left {
    color: white;
  }

  .box-right {
    align-items: center;
    display: flex;

    .failed-to-get-video {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      max-height: 45px;
      max-width: fit-content;
    }
  }

  .video_preview {
    // height: 320px;
    position: relative;
    align-items: center;
    display: flex;

    .video-view {
      max-height: 320px;
      width: 100%;
    }

    video {
      max-height: 320px;
      width: 100%;
    }
  }

  .mirror_change {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .control-video-message-box {
    min-height: 400px;
    border-radius: 5px;
    background-color: #f5f5f5;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .video-para {
      color: #162b4c;
      font-size: 16px;
      margin-bottom: 20px;
    }

    .video_preview {
      .video-img {
        height: 200px;
      }
    }
  }

  &.modal-video-box {
    min-height: initial;
    max-height: 200px;

    .video_preview {
      height: 200px;
    }

    .video_setting_preview {
      min-height: 180px;
    }

    .video-view {
      max-height: 200px;
    }

    video {
      max-height: 200px;
    }
  }

  &.portrait-view {}

  &.recorded {
    padding: 0px;
    min-height: initial !important;

    video {
      max-height: initial;
      width: 100%;
    }
  }
}

.modal-video-right-area {
  // min-height: 400px;
  // max-height: 400px;
  min-height: 450px;
  // max-height: 450px;
  padding-right: 10px;
  overflow: hidden;

  .content-overflow {
    // min-height: 140px;
    max-height: 120px;
  }
}

.overflow-condition {
  overflow-y: scroll;
}



.control-grid-views-main {
  // display: flex;
  // gap: 10px;
}

.control-grid-views-main>div {
  // width: 80%;
}

.control-grid-views-box {
  // width: 20%;

}

.tabs-control-room-main {
  width: 30% !important;

  .tabs-control-room {
    height: 100%;

    .ant-tabs-tab {
      padding: 10px 15px;

      .ant-tabs-tab-btn {
        font-weight: 600;
        font-family: Nunito Sans, sans-serif !important;
        color: grey;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #010b40;
        }
      }

      &:hover {
        .ant-tabs-tab-btn {
          color: #010b40;
        }
      }
    }

    .ant-tabs-ink-bar {
      background: #010b40;
    }

    &.chat-tab-data {
      .ant-tabs-content {
        height: 100%;

        .ant-tabs-tabpane {
          &.ant-tabs-tabpane-active {
            height: 100%;
          }

          &.chat-tab-txt {
            &.ant-tabs-tabpane-active {
              display: flex;
              height: 100%;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .ant-tabs-content {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;

    .hd-tab {
      color: #252525;
      font-size: 22px;
      margin-bottom: 15px;
    }

    .upcoming-box-main {
      background-color: #f5f5f5;
      padding: 3.5rem 1.5rem 2rem 1.5rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &.no-bg {
        background-color: white;
        padding: 0px;
      }

      .upcoming-img {
        width: 125px;
        margin-bottom: 25px;
      }

      .upcoming-para {
        color: #162b4c;
        font-size: 16px;
        margin-bottom: 25px;
      }

      .seperate-line {
        width: 50%;
        height: 2px;
        margin: 5px 15px 15px 15px;
        background-color: #ced3d9;
      }

      .login-input-chat {
        padding: 9px 11px;
      }
    }
  }
}

.event-preview-box-main {
  background-color: #ffffff;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);

  .event-banner {
    position: relative;
    border-radius: 5px 0 0 5px;
    background-color: #010b40;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 200px;
    min-height: 150px;
    color: #ffffff;

    .event-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 150px;

      .event-banner-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 150px;
        line-height: 146px;
        overflow: hidden;
        text-align: center;
        z-index: 0;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .schedule-badge {
        padding: 0.6rem 1rem 0.5em 1rem;
        margin: 8px 0 0 8px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        font-family: Nunito, sans-serif;
        line-height: 1;
        font-size: 66%;
        // font-size: 11px;
        color: #00aeb3;
        background-color: #cceff0;
        border-radius: 100px !important;
        display: inline-block;
        filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.1));
      }

      .ribbon-small {
        min-height: 10px;
        background: -webkit-gradient(linear,
            left top,
            left bottom,
            from(#fe8300),
            to(#fe8300));
        background: linear-gradient(180deg, #fe8300, #fe8300);
        padding: 3px 10px 0;
        bottom: 0;
        right: 0;
        color: #fff;
        -webkit-box-shadow: 0 -1px 1px rgb(0 0 0 / 25%);
        box-shadow: 0 -1px 1pxrgba (0, 0, 0, 0.25);
        border-radius: 4px 0 0 0;

        .fa-flask {
          margin-right: 7px;
        }
      }
    }
  }

  .preview-data-border {
    border-left: 1px solid rgb(224, 224, 224);
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .preview-date-main {
    margin: 0px 0;
    padding: 0 15px;
    min-width: 112px;

    .date-txt {
      font-size: 13px;
      margin-bottom: 5px;

      &.notes {
        margin-bottom: 10px;
      }
    }

    .preview-date {
      font-size: 14px;
      font-weight: 700 !important;
      margin-bottom: 5px;

      .fw-normal {
        font-weight: 400 !important;
      }
    }

    .preview-time {
      font-size: 14px;
      margin-bottom: 0px;

      &.notes-txt {
        min-height: 58px;
      }
    }

    .event-detail-txt {
      position: relative;
      font-size: 13px;
      margin: 10px 0 0 0;
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: 'Nunito Sans', sans-serif !important;

      .anticon {
        color: grey;
      }
    }

    &.producer-style {
      .preview-date {
        display: flex;
        gap: 10px;

        svg {
          color: grey;
        }
      }
    }
  }
}

.product-listing-table .ant-table-thead>tr>th {
  background: #010b40;
  color: white;
}

.product-listing-table .ant-table-body::-webkit-scrollbar {
  width: 10px;
}

.product-listing-table .ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.product-listing-table .ant-table-body::-webkit-scrollbar-thumb,
.product-listing-table .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #010b40 !important;
  border-radius: 10px;
}

.product-listing-table {
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }
}

.event-detail-skeleton {
  min-height: 120px;
  padding: 0 15px 0 0px;

  .ant-skeleton-header {
    .ant-skeleton-avatar-lg {
      width: 200px;
      height: 150px;
      line-height: 120px;
    }
  }
}

.control-products-listing-main-wrapper {
  max-height: 430px;

  .control-products-listing-main+.control-products-listing-main {
    // margin-top: 10px;
  }

  .control-products-listing-main {
    position: relative;
    margin-right: 10px;
    /* background-color: #ebebeb; */
    /* border-radius: 5px; */
    padding: 0 0 10px 0;
    margin: 0 10px 10px 0;
    border-bottom: 1px solid #dce4ec;

    // &:hover {
    //   background-color: #e1e1e1;
    // }
    &:last-child {
      border-bottom: 0px;
      padding-bottom: 0px;
    }

    .control-products-listing {
      position: relative;
      // padding-left: 105px;
      padding-left: 83px;
      min-height: 90px;

      .control-product-image {
        // width: 90px;
        width: 68px;
        height: 90px;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 0px;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 86px;
        text-align: center;
        // background-color: #b6b7bb;
        background-color: #f9f9f9;
        border: 1px solid #efefef;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .control-product-content {
        min-height: 90px;
        display: flex;
        // flex-direction: column;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 0;

        .product-left {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .control-product-title {
            font-size: 14px;
            font-weight: 700 !important;
            margin-bottom: 0;
            font-family: 'Nunito Sans', sans-serif !important;
            max-height: 40px;
            overflow: hidden;
            line-height: initial;
          }

          .control-product-price {
            font-size: 14px;
            // font-weight: 700 !important;
            margin-bottom: 0;
            font-family: 'Nunito Sans', sans-serif !important;
          }
        }

        .product-right {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-direction: column;

          .product-quantity {
            font-size: 14px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.control-room-full {
  max-width: 100% !important;
}

.gridVideo {
  height: 600px;
}

.host-info {
  padding: 10px 15px;
  border-bottom: 1px solid #e9ecef;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  border-right: 1px solid #e9ecef;
  width: 20%;
}

.host-info:nth-child(5) {
  border-right: 0px solid #e9ecef;
}

.host-box-wrapper {
  display: flex;
  justify-content: center;
  align-self: center;
}

.host-box {
  // border-right: 1px solid #e9ecef;
  // padding-right: 0px !important;
  // width: 20%;
  border-right: 0px solid #e9ecef;
  padding-right: 0px !important;
  width: 100%;
  border-top: 1px solid #e9ecef;
  display: flex;
  flex-direction: row;
}

.video-box {
  padding-left: 0px !important;
  width: 80%;
  background-color: #222222;

  &.localvideosingle {
    width: 100%;

    .single-grid {
      // display: block;
      display: flex;
      grid-template: initial;
      overflow: initial;
      height: initial;

      .col-6 {
        height: auto;

        // min-height: 550px;
        height: 500px;
        object-fit: cover;
      }

      .col-12 {
        height: auto;
        // min-height: 550px;
        height: 500px;
        object-fit: cover;
      }

      .videos-wrapper {
        // background-color: #ffffff!important;
      }

      video {
        position: static;
        // object-fit: initial!important;
      }
    }
  }

  &.ratio-4 {
    .single-grid {
      // width: 70%;
      // margin: auto;

      .col-12 {
        // height: initial;
        // object-fit: initial;
        width: 100%;
        padding-top: 75%;
        height: 0px;
        position: relative;
      }

      .col-6 {
        // height: initial;
        // object-fit: initial;
        width: 100%;
        padding-top: 75%;
        height: 0px;
        position: relative;
      }

      video {
        position: absolute;
      }
    }
  }

  &.ratio-16 {
    .single-grid {
      // width: 100%;

      // margin: auto;
      .col-12 {
        // height: initial;
        // object-fit: initial;
        width: 100%;
        padding-top: 56.25%;
        height: 0px;
        position: relative;
      }

      .col-6 {
        // height: initial;
        // object-fit: initial;
        width: 100%;
        padding-top: 56.25%;
        height: 0px;
        position: relative;
      }

      video {
        position: absolute;
      }
    }
  }

  &.ratio-4-multiview {
    .single-grid {
      width: 100%;

      .col-6 {
        // height: initial;
        // object-fit: initial;
        width: 100%;
        padding-top: 56.25%;
        height: 0px;
        position: relative;
      }
    }
  }
}

.ratio-4-preview-main {
  background-color: #222222 !important;
  width: 100%;

  .ratio-4-preview {

    // width: 70%;
    // margin: auto;
    >div {
      width: 100%;
      padding-top: 75%;
      height: 0px;
      position: relative;

      video {
        position: absolute;
      }
    }
  }
}

.ratio-8-preview-main {
  background-color: #222222 !important;
  width: 100%;

  .ratio-8-preview {

    // width: 70%;
    // margin: auto;
    >div {
      width: 100%;
      padding-top: 88.8%;
      height: 0px;
      position: relative;

      video {
        position: absolute;
      }
    }
  }
}

.ratio-16-preview-main {
  background-color: #222222 !important;
  width: 100%;

  .ratio-16-preview {
    width: 100%;
    margin: 0;

    >div {
      width: 100%;
      padding-top: 56.25%;
      height: 0px;
      position: relative;

      video {
        position: absolute;
      }
    }
  }
}

.ratio-6-preview-main {
  background-color: #222222 !important;
  width: 100%;

  .ratio-6-preview {
    >div {
      width: 66%;
      aspect-ratio: 0.6;
      margin: 0 auto;

      video {
        position: absolute;
      }
    }
  }
}

.no-video-style {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 460px;
}

.host-name {
  font-weight: 600;
  font-family: Nunito Sans, sans-serif !important;
  font-size: 13px;
  padding-bottom: 7px;
}

.host-control {
  // position: absolute;
  // bottom: 4px;
  // right: 4px;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.host-button {
  border: 1px solid #010b40;
  background: #010b40;
  color: #ffffff;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 11px;
  /* line-height: 26px; */
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    margin-left: 0;
  }

  &:hover {
    border: 1px solid #134a7c;
    background: #134a7c;
    color: white;
  }
}

.event-timer-main {
  display: flex;
  margin: 50px auto;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;

  .ant-spin-dot-item {
    background-color: #010b40;
  }

  .ant-spin {
    color: #010b40;
  }
}

.no-user-found {
  background-color: black;
  color: white;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;

  .no-user-icon {
    color: white;
    font-size: 25px;
  }

  h6 {
    color: white;
  }
}

.start-meeting-main {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: calc(100% - 95px);
  min-height: 400px;
}

.controls-color svg g {
  // fill: green;
}

.controls-color .ch-control-bar-item-label {
  // color: green !important;
}

.controls-color .ch-control-bar-item-iconButton:hover {
  // background-color: red !important;
}

.video-enable .ch-control-bar-item-iconButton {
  background-color: green !important;
  border-color: green !important;
  color: #fff !important;
}

.video-disable .ch-control-bar-item-iconButton {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  color: #fff !important;
}

.video-enable .ch-control-bar-item-caret:hover {
  background-color: green !important;
}

.video-disable .ch-control-bar-item-caret:hover {
  background-color: #dc3545 !important;
}

.video-controls-main .ch-control-bar-item-caret:focus {
  background: transparent !important;
  color: #3f4149;
}

// .video-disable .ch-control-bar-item-caret:focus {
//   background-color: transparent !important;
// }
// .video-enable .ch-control-bar-item-caret:focus{
//   background-color: transparent !important;
// }

.hide-mob-text {}

.show-mob-btn {
  display: none;
}

.copy-url-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.content-share-grid {
  min-height: 460px;
}

.control-product-copy {
  .default-btn.small-btn {
    min-width: 73px;
  }
}



.video-full-main {

  .video-full {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.single-numbrer-view-main {
  width: 100%;
  height: 100%;
  display: flex;
  color: white;
  position: absolute;
  left: 0;
  top: 0;
}

.single-numbrer-view-main .single-numbrer-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62.5%;
  text-align: center;
  font-size: 35px;
  border-right: 1px solid white;
}

.single-numbrer-view+.single-numbrer-view {
  width: 38.5%;
  border-right: 0px solid white;
}

@media only screen and (max-width: 1440px) {
  // .video-box {
  //   &.localvideosingle {
  //     .single-grid {
  //       .col-6 {
  //         height: auto;
  //         min-height: 300px;
  //         object-fit: cover;
  //       }
  //     }
  //   }
  // }
}

@media only screen and (max-width: 1280px) {
  .copy-url-area {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .no-video-style {
    min-height: 260px;
  }

  .cta-fill-area {
    .cta-para {
      font-size: 13px;
    }

    .default-btn.outline-white {
      font-size: 13px;
      // padding: 6.4px 15px;
    }
  }

  .control-boxes-main {
    .control-boxes-box {
      .live-stats-main {
        .live-stats-box {
          .stats-hd {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .video-box {
    &.localvideosingle {
      .single-grid {
        .col-6 {
          height: auto;
          min-height: 180px;
          height: 180px;
          object-fit: cover;
        }

        .col-12 {
          height: auto;
          min-height: 180px;
          height: 180px;
          object-fit: cover;
        }
      }
    }
  }

  .control-boxes-main .control-boxes-box.tabs-control-room-main {
    height: auto !important;
  }

  .control-video-box-main {
    min-height: 290px;
  }

  .content-listing-wrapper {
    .live-shopping-grid {
      &.three-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .control-boxes-main {
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;

    .control-boxes-box {
      // width: 100%;
      // max-width: 500px;
      width: 100% !important;
      max-width: 100%;
      align-self: center;
    }
  }

  .cta-fill-area {
    display: block;
    flex-direction: column;

    .cta-para {
      margin-bottom: 10px;
    }

    .default-btn.outline-white {
      display: inline-flex !important;
    }
  }

  .control-room-top-area {
    flex-direction: column;
    align-items: start;
    gap: 10px;

    .control-top-box {
      align-self: start;
    }
  }

  .event-preview-box-main {
    max-width: 350px;
    display: table;
    margin: 0 auto;

    .event-banner {
      border-radius: 5px 5px 0 0;
      margin-bottom: 10px;
      width: 100%;
    }

    .preview-data-border {
      border-left: 0px;
      margin: 0;
    }

    .preview-date-main {
      margin: 0px 10px;
      padding: 15px 5px 10px 5px;
      border-bottom: 1px solid #e1e1e1;

      &.no-border-bottom {
        border-bottom: 0px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .content-listing-wrapper {
    .live-shopping-grid {
      &.three-grid {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 15px;
        grid-column-gap: 15px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  .host-box-wrapper {
    flex-direction: column;
  }

  .host-box {
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-right: 0px;
    flex-wrap: wrap;
  }

  .host-info {
    padding: 10px 10px;
    flex-direction: column;
    border-right: 1px solid #e9ecef;
    /* border-left: 1px solid #e9ecef; */
    width: 50%;
  }

  .host-info:nth-child(2),
  .host-info:nth-child(4) {
    border-right: 0px solid #e9ecef;
  }

  .host-info:nth-child(5) {
    border-right: 1px solid #e9ecef;
  }

  .video-box {
    width: 100%;
  }

  .get-tour-space {
    margin-bottom: 10px;
  }

  .w-100-mob {
    width: 100% !important;
  }

  a.mob-style {
    display: inline-flex;
    margin: 0px 5px 5px 0px !important;
    padding: 6.4px 15px !important;
    min-width: initial !important;
  }

  a.mob-style+.ant-dropdown-button {
    top: 2px;
    margin: 0 5px 0 0px !important;
  }

  .tabs-control-room-main {
    .tabs-control-room {
      .ant-tabs-tab {
        padding: 10px 5px;
        font-size: 13px;
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 20px;
      }
    }

    .ant-tabs-content {
      .hd-tab {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .upcoming-box-main {
        padding: 15px;

        .upcoming-img {
          width: 70px;
          margin-bottom: 15px;
        }

        .upcoming-para {
          font-size: 13px;
          margin-bottom: 15px;
        }

        &.no-bg {
          padding: 0rem;
        }
      }
    }
  }

  .control-boxes-main {
    .control-boxes-box {
      .live-stats-main {
        .live-stats-box {
          .stats-hd {
            font-size: 11px;

            &.product-view-hd {
              font-size: 9px;
              line-height: 17px;
            }
          }

          .stats-value {
            font-size: 12px;
          }
        }
      }
    }
  }

  .control-room-top-area {
    .control-top-box {
      /* align-self: start; */
      flex-direction: initial;
      align-items: initial;
      justify-content: initial;
      display: block;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .control-product-copy {
    .default-btn.small-btn {
      min-width: 57px;
      padding: 3px 5px 0 5px;
    }
  }

  .no-video-style {
    min-height: 210px;
  }

  .host-control {
    gap: 3px;
  }

  // .host-button {
  //     width: 25px;
  //   height: 25px;
  // }
  .event-preview-box-main {
    width: 100% !important;
    max-width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .hide-mob-text-main {
    padding: 0.28rem 0.5rem !important;

    .hide-mob-text {
      display: none;
    }

    &.golive {
      padding: 0.59rem 0.5rem !important;
    }
  }

  .show-mob-btn {
    display: block;
  }

  .leave-btn {
    // font-size: 15px;
    color: white;
    // width: 1.5rem;
    // height: 1.5rem;
  }
}

@media only screen and (max-width: 440px) {
  .control-video-box-main {
    min-height: 205px;
  }

  .upcoming-box-main {
    .ant-form-inline {
      width: 100%;
      flex-direction: column;
      gap: 15px;
    }
  }
}


.producer-input-box {
  border-right: 0 solid #e9ecef;
  border-top: 1px solid #e9ecef;
  display: flex;
  flex-direction: row;
  padding-right: 0 !important;
  width: 100%;
}

.producer-input-info {
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  border-right: 2px solid #e9ecef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  position: relative;
  text-align: center;
  width: 25%;
  // height: 122px;
}

.producer-title {
  font-size: 13px;
}

.producer-input-control {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: end;
  // margin-top: 5px;
}

.producer-video-thumb-main {
  width: 100%;
  height: 142px;
  background-color: #000000;
  display: flex;
}

.dimension-fix {
  overflow: hidden;

  .ch-video {
    width: initial;
    margin: auto;
    /* position: static; */
    height: initial;
    /* object-fit: initial!important; */
    max-width: 100%;
    max-height: 100%;
  }
}

.producer-video-thumb {
  width: 100%;
  // height: 100%;
}

.producer-button {
  border: 1px solid #010b40;
  background: #010b40;
  color: #ffffff;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 11px;
  /* line-height: 26px; */
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    margin-left: 0;
  }

  &:hover {
    border: 1px solid #134a7c;
    background: #134a7c;
    color: white;
  }
}

.producer-video-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  padding: 5px 5px;
}

.producer-btn {
  gap: 5px;
  display: flex;

  svg {
    position: relative;
    top: -1px;
  }
}

.producer-setting>section {
  padding-bottom: 25px;
}

.producer-setting .ant-slider-handle {
  border: solid 2px #010b40;
}

.producer-setting .ant-slider-handle:focus {
  border-color: #010b40;
}

.producer-setting .ant-slider-track,
.producer-setting .ant-slider .ant-slider-track {
  background-color: #010b40;
}


@media only screen and (max-width: 767px) {
  .producer-input-box {
    flex-wrap: wrap;
  }

  .producer-input-info {
    width: 50%;
  }
}

.switch-toggle-color.ant-switch-checked {
  background-color: #010b40;
}