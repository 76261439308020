.public-header {
  padding: 15px 0;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  background: white;
  font-family: Nunito Sans, sans-serif !important;

  .public-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 25px;

    .header-left {
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }
}

.public-logo {
  //  width: 110px;
  margin: 0;

  img {
    width: 110px;
  }
}

.default-btn {
  &.rounded {
    border-radius: 100px !important;
    padding: 8px 10px 0px 10px !important;
  }
}

.public-menu {
  width: 100%;
  // display: inline-block;
  margin: 0;

  > ul {
    //   float: right;
    list-style-type: none;
    margin: 0px;

    > li {
      padding: 0px 15px;
      float: left;
      position: relative;

      &:last-child {
        padding-right: 0px;
      }

      > a {
        padding: 0px;
        color: #7b8994;
        font-size: 16px;
        font-family: 'Nunito Sans', sans-serif !important;
        font-weight: 600;

        &.active {
          color: #010b40;
          font-weight: 700;
        }
      }

      &.active > a {
        color: #010b40;
        font-weight: 700;
      }

      > a {
        &:hover,
        &.active,
        &:focus {
          color: #010b40;
        }
      }
    }
  }
}

.public-footer {
  background: #222328;

  .footer-content {
    // padding: 10px 0;
    padding: 15px 0;

    .copyright {
      color: white;
      font-size: 14px;
      font-family: 'Nunito Sans', sans-serif !important;
    }
  }

  .footer-inline-nav {
    list-style-type: none;
    margin: 10px 0 10px 0;
    padding: 0;

    li {
      float: left;
      padding: 0 12px;
      position: relative;

      &:last-child {
        padding-right: 0px;
      }

      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 11px;
        background: white;
        left: 0px;
        top: 30%;
      }

      &:first-child:before {
        display: none;
      }

      a {
        font-size: 14px;
        font-weight: 400;
        color: white;
        font-family: 'Nunito Sans', sans-serif !important;

        &:hover {
          color: #c2c2c2;
        }
      }
    }
  }
}

.customer-video {
  width: 70%;
}


.general-section {
  padding: 60px 0;
}

.list-simple {
  padding-left: 20px;
  li {
    margin-bottom: 10px;
    color: #252525;
    strong {
      // font-family: "SegoeUIBold";
    }
  }
}

.highlight-txt {
  color: #007bff;
}
.highlight-txt {
  color: #007bff;
  &:hover {
    color: #007bff;
  }
}

.privacy_header {
  // text-align: center;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
  -webkit-box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
  -moz-box-shadow: 0 4px 8px 0 rgba(10, 22, 70, 0.15);
  background-color: #fff;
  padding: 30px;
  h2 {
    color: #010b40;
    font-weight: 800 !important;
    font-size: 120px;
    margin-bottom: 40px;
  }
  .privacy-img {
    margin-bottom: 40px;
    max-width: 100%;
    height: auto;
  }
  h3 {
    color: #252525;
    font-weight: 800 !important;
    font-size: 30px;
    margin-bottom: 10px;
  }
  h5 {
    color: #252525;
    font-weight: 800 !important;
    font-size: 22px;
    line-height: 2rem;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  h6 {
    color: #252525;
    font-weight: 800 !important;
    font-size: 16px;
    line-height: 2rem;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  p {
    color: #252525;
    font-weight: 400 !important;
    strong {
      // font-family: "SegoeUIBold";
      font-family: Nunito Sans, sans-serif !important;
    }
  }
}

.table-simple {
  padding: 20px 0;
  font-family: Nunito Sans, sans-serif !important;
  thead{
    background: #010b40;
    color: #fff;
  tr {
    th {
      width: 410px;
      vertical-align: middle;
      &:first-child {
        width: 260px;
      }
    }
  }
  }
}

@media screen and (min-width: 1440px) {
  .bioshop-sec-main .bioshop-container .bioshop-box .public-why-home-img {
    width: 70%;
  }

  .mid-width {
    -webkit-padding-end: 144px;
    -webkit-padding-start: 144px;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1632px;
    padding-inline-end: 144px;
    padding-inline-start: 144px;
  }
  .max-1140 {
    max-width: 1140px !important;
  }
}

@media screen and (min-width: 600px) {
  .mid-width {
    -webkit-padding-end: 48px;
    -webkit-padding-start: 48px;
    padding-inline-end: 48px;
    padding-inline-start: 48px;
  }
}

@media screen and (min-width: 100px) {
  .mid-width {
    -webkit-padding-end: 20px;
    -webkit-padding-start: 20px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .general-section {
    padding: 40px 0;
  }
  
}

@media only screen and (max-width: 767px) {
  .public-header {
    padding: 7px 0;

    .mid-width {
      -webkit-padding-end: 10px;
      -webkit-padding-start: 10px;
      padding-inline-end: 10px;
      padding-inline-start: 10px;
    }

    .public-logo {
      img {
        width: 70px;
      }
    }

    @media only screen and (max-width: 767px) {
      .customer-video {
        width: 100%;
      }
      .public-header {
        padding: 7px 0;

        .mid-width {
          -webkit-padding-end: 10px;
          -webkit-padding-start: 10px;
          padding-inline-end: 10px;
          padding-inline-start: 10px;
        }

        .public-menu {
          ul {
            padding: 0;

            li {
              padding: 0 5px;

              a {
                font-size: 12px;
              }
            }
          }
        }
      }
      .default-btn {
        &.rounded {
          font-size: 12px;
          margin: 0 0 0 5px;
          padding: 5px 10px 0px 10px !important;
          height: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .public-header {
    .default-btn.rounded {
      padding: 5px 7px 0px 7px !important;
    }
    .public-header-content {
      gap: 0;
      .header-left {
        gap: 10px;

        .public-menu {
          width: 120px;
          ul {
            padding: 0;
            li {
              a {
                font-size: 14px;
              }
              padding: 0 10px;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
